import React from "react"
import Layout from "@/components/layout"
import FormContent from "@/components/leadsForm"
import Footer from "@/components/footer"

const AtrioForm = () => {
  return (
    <Layout>
      <FormContent />
      <Footer />
    </Layout>
  );
};

export default AtrioForm;
